<template>
  <div :class="$style.attributes">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectAttribute"
          @handleFindItems="handleFindAttributes"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-button
        :class="$style.button"
        type="primary"
        @click="$router.push('attributes/ranking')"
      >
        Ранжирование
      </el-button>
      <el-button
        :class="$style.button"
        type="primary"
        @click="$router.push('attributes/create')"
      >
        Создать
      </el-button>
    </div>
    <el-table :data="attributes" stripe :class="$style.table">
      <el-table-column prop="name" label="Название"> </el-table-column>
      <el-table-column prop="slug" label="URL"> </el-table-column>
      <el-table-column
        prop="attributeOptions"
        label="Варианты"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scopeOp">
          <span
            v-for="(opt, i) in parseOptions(scopeOp.row.attributeOptions)"
            :key="opt + String(i)"
          >
            {{ opt }} &ensp;
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="isDisplayed" label="В фильтрах" width="100">
        <template slot-scope="scope">
          <input
            type="checkbox"
            onclick="return false"
            :checked="scope.row.isDisplayed"
            :class="$style.checkbox"
          />
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            :edit-link="'attributes/' + String(scope.row.id)"
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'

export default {
  components: { ActionButtons, Autocomplete },
  data() {
    return {
      attributes: [],
      isOpenModal: false,
      total: 0,
      page: 1,
      limit: 20,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      if (this.search) {
        query.search = this.search
      }

      const { value, error } =
        await delivery.ProductsCore.AttributesActions.getList(query)
      if (error) return
      this.attributes = value.data
      this.total = value.meta.count

      loading.close()
    },
    parseOptions(data) {
      return data?.map((op) => op.value)
    },
    async remove(data) {
      const conf = confirm('Вы точно хотите удалить ' + data.name)
      if (conf) {
        const res = await delivery.ProductsCore.AttributesActions.delete(
          data.id,
        )
        if (!res.error) {
          this.getList()
        }
      }
    },
    handleSelectAttribute(selectedAttribute) {
      this.$router.push('/addwine/catalog/attributes/' + selectedAttribute.id)
    },
    async handleFindAttributes() {
      await this.getList()
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ProductsCore.AttributesActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
  },
}
</script>

<style lang="scss" module>
.attributes {
  padding: 1rem;
  .wrapper {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .search {
      margin-right: auto;
      display: flex;
      align-items: center;
      .button {
        margin-left: 0;
        margin-bottom: 0;
        border-radius: 0;
      }
      input {
        border-radius: 0;
      }
    }
  }
  .table {
    .checkbox {
      pointer-events: none;
      display: block;
      margin: 0 auto;
    }
  }
  .modal {
    label {
      margin: 0;
    }
  }
  .pagination {
    text-align: center;
  }
}
</style>
